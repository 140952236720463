/*
 * radical-stage.ts
 *
 * This file defines the RadicalStage class, which serves as the primary 3D scene manager
 * for the application. It handles scene objects, characters, assets, cameras, lighting,
 * environment management, and user presence visualization.
 */

// -----------------------------------------------------------------------------
// Import required modules, classes, and constants from Three.js and other libraries
// -----------------------------------------------------------------------------
import {
  BufferAttribute,
  Color,
  ColorRepresentation,
  CubeTexture,
  Fog,
  GLSL3,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  Object3D,
  Quaternion,
  Scene,
  ShaderMaterial,
  Texture,
  Vector3,
  WireframeGeometry,
  MathUtils,
  Light,
  PlaneGeometry,
  ShadowMaterial,
  DirectionalLight,
} from 'three';
import { RadicalCharacter } from '@radical/radical-character-three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GroundedSkybox } from 'three/examples/jsm/objects/GroundedSkybox';
import { AssetModelLoaded, ObjectSnapshot } from '@radical/canvas-fe-types';
import { UserCamera } from '../user-camera';
import { AssetObject } from '../radical-asset';
import { generateGrayscaleColor } from '../../helpers/ColorHelpers';
import { BackgroundMesh } from './Background/BackgroundMesh';
import { updateGroundedSkyboxShader, updateShadowPlaneShader } from '@radical/radical-shaders';

//@ts-ignore
import filePathCharPlaceholder from '../../public/assets/male_body_base_mesh.glb';
//@ts-ignore
import filePathVisualizerPlane from '../../public/assets/Floor_core_2.glb';
import {
  CanvasCallbacksNames,
  CanvasCallbacksTypes,
  ObjectType,
  VisitorInfo,
  AssetModelInfo,
  CameraInfo,
  CharacterModelInfo,
  EnvSettings,
  LightInfo,
  BackgroundType,
  SceneObject,
} from '@radical/canvas-types';
import { CinematicCamera } from '../cinematic-camera';
import { RadicalPointLight, RadicalSpotLight } from '../radical-lights';
import { DEG2RAD } from 'three/src/math/MathUtils';

// -----------------------------------------------------------------------------
// Type definitions
// -----------------------------------------------------------------------------

/**
 * Type definition for a 3D visitor in the scene, containing both their camera representation
 * and visitor metadata information.
 */
type Visitor3D = {
  cone: UserCamera; // Visual representation of the user's camera in the scene
  visitor: VisitorInfo; // Metadata about the visitor including position, rotation, and identity
};

// -----------------------------------------------------------------------------
// Default constants and configuration parameters
// -----------------------------------------------------------------------------

/**
 * Configuration parameters for the grounded skybox used in the environment
 */
const paramsGroundedSkybox = {
  height: 15, // Height of the skybox from the ground
  radius: 1000, // Radius of the skybox sphere
  enabled: true, // Whether the grounded skybox is enabled by default
};

// -----------------------------------------------------------------------------
// RadicalStage Class Definition
// -----------------------------------------------------------------------------

/**
 * The RadicalStage class manages the 3D scene containing all objects, characters,
 * lighting, cameras, and environment settings. It extends the Three.js Scene class
 * to provide specialized functionality for the Radical platform.
 */
export class RadicalStage extends Scene {
  // -----------------------------------------------------------------------------
  // Private properties - Identity and Scene Management
  // -----------------------------------------------------------------------------

  private ownId: string | undefined; // Unique identifier for the current user or session
  private assetsInScene: Map<string, AssetObject | Object3D>; // Collection of 3D assets in the scene
  private asetsInUI: Map<string, string>; // Mapping between asset IDs and UI display names
  private camerasInUI: Map<string, string>; // Mapping between camera IDs and UI display names
  private lightsInUI: Map<string, string>; // Mapping between light IDs and UI display names

  // -----------------------------------------------------------------------------
  // Private properties - Character Management
  // -----------------------------------------------------------------------------

  private charactersInScene: Map<string, RadicalCharacter>; // Collection of characters present in the scene
  private charactersInUI: Map<string, string>; // Mapping between character IDs and UI display names
  private loaderModelChar: Object3D = new Object3D(); // Template for loading character models

  // -----------------------------------------------------------------------------
  // Private properties - Camera and Light Management
  // -----------------------------------------------------------------------------

  private camerasInScene: Map<string, CinematicCamera>; // Collection of cinematic cameras in the scene
  private lightsInScene: Map<string, RadicalPointLight | RadicalSpotLight>; // Collection of lights in the scene

  // -----------------------------------------------------------------------------
  // Private properties - Callbacks and Timing
  // -----------------------------------------------------------------------------

  private callbacks?: CanvasCallbacksTypes; // Optional callbacks for canvas events
  private duration: number = 30 * 60; // Default duration for animations (30 minutes)

  // -----------------------------------------------------------------------------
  // Private properties - User/Visitor Management
  // -----------------------------------------------------------------------------

  private usersInScene: Map<string, Visitor3D> = new Map<string, Visitor3D>(); // Users currently in the scene
  private userHolders: Object3D = new Object3D(); // Container for user-related objects
  private followingUser: Visitor3D | undefined; // User that the current session is following

  // -----------------------------------------------------------------------------
  // Private properties - Visibility Flags
  // -----------------------------------------------------------------------------

  private jointsVisible: boolean = false; // Whether character joints/skeleton is visible
  private sceneNamesVisible: boolean = false; // Whether scene object names are visible
  private backgroundEnvVisible: boolean = false; // Whether background environment is visible
  private userPovVisible: boolean = true; // Whether user POV cameras are visible

  // -----------------------------------------------------------------------------
  // Private properties - Environment and Background
  // -----------------------------------------------------------------------------

  private envSettings: EnvSettings = { floorProjection: false, intensity: 1, rotation: 0 }; // Environment map settings
  private backgroundType: BackgroundType = BackgroundType.BLANK; // Type of background to display
  private floor: Object3D; // Floor object for scenes
  private groundedSkybox: GroundedSkybox | undefined; // Skybox with ground projection
  private backgroundMesh: BackgroundMesh; // Mesh that replaces scene.background

  // -----------------------------------------------------------------------------
  // Private properties - Lighting and Shadows
  // -----------------------------------------------------------------------------

  private numTotalLightsAddedToScene: number = 0; // Counter for lights added to the scene
  private shadowCatchingPlane: Mesh = new Mesh(new PlaneGeometry(2000, 2000), new ShadowMaterial()); // Plane for catching shadows
  private directionalLight: DirectionalLight = new DirectionalLight(0xffddbb, 1); // Primary directional light

  /**
   * Constructor for the RadicalStage class. Initializes the 3D scene with all required components.
   *
   * @param callbacks - Optional canvas event callbacks for handling interactions
   * @param isVisualizer - Whether this instance is a visualizer (affects rendering configuration)
   * @param isLive - Whether this instance is used in a live session
   * @param backgroundColor - Background color for the scene
   */
  constructor(
    callbacks?: CanvasCallbacksTypes,
    isVisualizer?: boolean,
    isLive?: boolean,
    backgroundColor?: ColorRepresentation
  ) {
    // Call parent constructor to initialize the Three.js Scene
    super();

    // -----------------------------------------------------------------------------
    // Initialize scene structure and collections
    // -----------------------------------------------------------------------------

    // Add user holders container to the scene
    this.add(this.userHolders);

    // Initialize collections for scene management
    this.assetsInScene = new Map<string, AssetObject>();
    this.charactersInScene = new Map<string, RadicalCharacter>();
    this.charactersInUI = new Map<string, string>();
    this.asetsInUI = new Map<string, string>();
    this.camerasInUI = new Map<string, string>();
    this.lightsInUI = new Map<string, string>();
    this.camerasInScene = new Map<string, CinematicCamera>();
    this.lightsInScene = new Map<string, RadicalPointLight | RadicalSpotLight>();

    // Create placeholder 3D object for character loading
    this.loaderModelChar = this.createLoaderMesh();

    // -----------------------------------------------------------------------------
    // Initialize floor and visualizer components
    // -----------------------------------------------------------------------------

    // Create and add the floor to the scene (hidden by default)
    this.floor = this.createVisualizerFloor(isLive);
    this.add(this.floor);
    this.floor.visible = false;

    // Show floor if in visualizer mode
    if (isVisualizer) {
      this.floor.visible = true;
    }

    // Store callbacks if provided
    if (callbacks) this.callbacks = callbacks;

    // -----------------------------------------------------------------------------
    // Initialize background components
    // -----------------------------------------------------------------------------

    // Create background mesh with specified color
    const backgroundColorRGB = new Color(backgroundColor);
    this.backgroundMesh = new BackgroundMesh(10000, 32, 32, backgroundColorRGB);
    this.addToScene(this.backgroundMesh);

    // Set up lighting and environment components
    this.initializeEnvironment();
  }

  /**
   * Initializes the scene's lighting and environment components.
   * Sets up directional light and shadow-catching plane.
   * @private
   */
  private initializeEnvironment() {
    // -----------------------------------------------------------------------------
    // Create primary directional light
    // -----------------------------------------------------------------------------

    // Set directional light position and target
    this.directionalLight.position.set(13, 12, 8);
    this.directionalLight.target.position.set(0, 0, 0);
    this.add(this.directionalLight.target);
    this.directionalLight.castShadow = true;

    // Configure shadow camera parameters
    this.directionalLight.shadow.camera.near = 0.1;
    this.directionalLight.shadow.camera.far = 75;
    this.directionalLight.shadow.camera.right = 17;
    this.directionalLight.shadow.camera.left = -17;
    this.directionalLight.shadow.camera.top = 17;
    this.directionalLight.shadow.camera.bottom = -17;

    // Configure shadow map quality settings
    this.directionalLight.shadow.mapSize.width = 1024;
    this.directionalLight.shadow.mapSize.height = 1024;
    this.directionalLight.shadow.radius = 4;
    this.directionalLight.shadow.blurSamples = 8;
    this.directionalLight.shadow.bias = -0.0001;
    this.directionalLight.shadow.normalBias = -0.0005;

    // Add the light to the scene
    this.add(this.directionalLight);

    // -----------------------------------------------------------------------------
    // Create shadow-catching plane for HDRI floor projection
    // -----------------------------------------------------------------------------

    // Configure shadow-catching plane geometry and material
    this.shadowCatchingPlane.geometry.rotateX(-Math.PI / 2);
    (this.shadowCatchingPlane.material as ShaderMaterial).opacity = 2 / 3;
    (this.shadowCatchingPlane.material as ShaderMaterial).onBeforeCompile = (shader: any) =>
      updateShadowPlaneShader(shader);
    this.shadowCatchingPlane.receiveShadow = true;
    this.shadowCatchingPlane.position.setY(0.001);
    this.shadowCatchingPlane.frustumCulled = false;

    // Add the shadow-catching plane to the scene
    this.add(this.shadowCatchingPlane);
  }

  // -----------------------------------------------------------------------------
  // Environment and Background Management Methods
  // -----------------------------------------------------------------------------

  /**
   * Controls the visibility of the floor shadow projection plane.
   *
   * @param b - Boolean indicating whether the shadow projection should be visible
   */
  public setFloorShadowProjectionVisibility(b: boolean) {
    this.shadowCatchingPlane && (this.shadowCatchingPlane.visible = b);
  }

  /**
   * Updates the scene environment settings based on provided parameters.
   * Controls floor projection, environment intensity, and rotation.
   *
   * @param settings - Partial environment settings to update
   */
  public updateSceneEnvironment(settings: Partial<EnvSettings>) {
    const { floorProjection, intensity, rotation } = settings;

    // Update floor projection if specified
    if (floorProjection !== undefined) {
      this.envSettings.floorProjection = floorProjection;
      this.setFloorProjection(floorProjection);
    }

    // Update environment intensity if specified
    if (intensity !== undefined) {
      //@ts-ignore
      this.envSettings.intensity = parseFloat(intensity);
      //@ts-ignore
      this.setEnvIntensity(parseFloat(intensity));
    }

    // Update environment rotation if specified
    if (rotation !== undefined) {
      //@ts-ignore
      this.envSettings.rotation = parseInt(rotation);
      //@ts-ignore
      this.setEnvRotation(parseInt(rotation));
    }
  }

  /**
   * Retrieves the current environment settings.
   *
   * @returns Current environment settings object
   */
  public getEnvSettings(): EnvSettings {
    return this.envSettings;
  }

  /**
   * Gets the background mesh used in the scene.
   *
   * @returns The background mesh object
   */
  public getBackground(): Object3D {
    return this.backgroundMesh;
  }

  /**
   * Returns a cloned instance of the character loader model.
   * Used for initializing new character instances.
   *
   * @returns A clone of the character loader model
   */
  public getCharLoaderClone(): Object3D {
    return this.loaderModelChar.clone();
  }

  /**
   * Adds an object to the scene and registers it in the assets collection.
   *
   * @param obj - The 3D object or asset to add to the scene
   */
  public addToScene(obj: AssetObject | Object3D) {
    this.assetsInScene.set(obj.uuid, obj);
    this.add(obj);
  }

  /**
   * Controls the visibility of scene object labels.
   *
   * @param b - Boolean indicating whether scene names should be visible
   */
  public setSceneNamesVisible(b: boolean) {
    this.sceneNamesVisible = b;
  }

  /**
   * Gets the current visibility status of scene names.
   *
   * @returns Boolean indicating whether scene names are visible
   */
  public getSceneNamesVisible(): boolean {
    return this.sceneNamesVisible;
  }

  /**
   * Sets the rotation of the environment and environment mesh.
   * Also adjusts the directional light to match the environment rotation.
   *
   * @param n - Rotation angle in degrees
   */
  public setEnvRotation(n: number) {
    // Convert degrees to radians
    const rad = MathUtils.degToRad(n);

    // Set environment rotation
    this.environmentRotation.set(0, rad, 0);
    this.envSettings.rotation = n;

    // Update background mesh rotation if available
    if (this.backgroundMesh !== undefined) {
      this.backgroundMesh.setRotation(0, Math.PI + rad, 0);
    } else {
      console.warn('Cant set rotation on backgroundMesh');
    }

    // Update grounded skybox rotation if available
    if (this.groundedSkybox !== undefined) {
      this.groundedSkybox!.rotation.set(0, rad, 0);
    } else {
      console.warn('Cant set rotation on groundedSkybox');
    }

    // Update directional light position to match environment rotation
    if (!this.directionalLight || this.directionalLight instanceof DirectionalLight === false) {
      return;
    }

    // Calculate light position based on distance from origin and new rotation
    const radius = Math.sqrt(
      this.directionalLight.position.x * this.directionalLight.position.x +
        this.directionalLight.position.z * this.directionalLight.position.z
    );

    // Calculate new positions with offset angle (20 degrees)
    this.directionalLight.position.x = radius * Math.cos((20 + n) * DEG2RAD * -1);
    this.directionalLight.position.z = radius * Math.sin((20 + n) * DEG2RAD * -1);

    // Update light target to ensure correct orientation
    this.directionalLight.target.updateMatrixWorld();
  }

  /**
   * Sets the intensity of the environment lighting.
   * Affects background color, grounded skybox, shadow plane opacity, and directional light.
   *
   * @param n - Intensity value (typically between 0 and 1)
   */
  public setEnvIntensity(n: number) {
    // Update environment intensity properties
    this.environmentIntensity = n;
    this.envSettings.intensity = n;

    // Generate a grayscale color based on intensity
    const color = new Color(generateGrayscaleColor(n));

    // Update background mesh color if available
    if (this.backgroundMesh !== undefined) {
      this.backgroundMesh.setColor(color);
    } else {
      console.warn('*** Cant update material on backgroundMesh');
    }

    // Update grounded skybox color if available
    if (this.groundedSkybox !== undefined) {
      this.groundedSkybox!.material.color = color;
      this.groundedSkybox!.material.needsUpdate = true;
      this.groundedSkybox!.material.onBeforeCompile = (shader: any) => updateGroundedSkyboxShader(shader);
    } else {
      console.warn('*** Cant update material on groundedSkybox');
    }

    // Update shadow catching plane opacity if grounded skybox is available
    if (this.groundedSkybox !== undefined) {
      (this.shadowCatchingPlane.material as ShadowMaterial).opacity = (2 / 3) * parseFloat(String(n));
      this.groundedSkybox.material.needsUpdate = true;
      this.groundedSkybox.material.onBeforeCompile = (shader: any) => updateGroundedSkyboxShader(shader);
    } else {
      console.warn('*** Cant update material on shadowCatchingPlane');
    }

    // Update directional light intensity
    this.directionalLight.intensity = parseFloat(String(n));
  }

  /**
   * Toggles the floor projection mode for the environment.
   * When enabled, shows grounded skybox and shadow catching plane.
   * When disabled, shows background mesh instead.
   *
   * @param b - Boolean indicating whether floor projection should be enabled
   */
  public setFloorProjection(b: boolean) {
    this.envSettings.floorProjection = b;
    if (b) {
      // Enable floor projection components
      this.groundedSkybox && this.add(this.groundedSkybox);
      this.shadowCatchingPlane && this.add(this.shadowCatchingPlane);
      this.removeFromScene(this.backgroundMesh);
    } else {
      // Disable floor projection components
      this.groundedSkybox && this.remove(this.groundedSkybox);
      this.shadowCatchingPlane && this.remove(this.shadowCatchingPlane);
      this.addToScene(this.backgroundMesh);
    }
  }

  // -----------------------------------------------------------------------------
  // Camera and UI Management Methods
  // -----------------------------------------------------------------------------

  /**
   * Adds a cinematic camera to the UI with the specified name.
   *
   * @param id - Unique identifier for the camera
   * @param camera - Camera information object
   * @param sceneName - Optional display name for the camera
   */
  public addCameraToUI(id: string, camera: CameraInfo, sceneName?: string) {
    let max = 0;

    // If no scene name provided, calculate next available camera number
    sceneName === undefined &&
      this.camerasInUI.forEach((name, ids) => {
        const nb = name.split('Camera ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });

    // Use provided name or generate a default one
    const name = sceneName || `Camera ${max + 1}`;

    // Add camera to the UI mapping
    this.camerasInUI.set(id, name);

    // Trigger callback to inform UI about the new camera
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      const oa: SceneObject = {
        id: id,
        name: name,
        type: ObjectType.CINEMATIC_CAMERA,
        info: camera,
      };
      if (cb) {
        cb(oa);
      }
    }
  }

  /**
   * Adds a light to the UI with the specified name.
   *
   * @param id - Unique identifier for the light
   * @param light - Light information object
   * @param sceneName - Optional display name for the light
   */
  public addLightToUI(id: string, light: LightInfo, sceneName?: string) {
    let max = 0;

    // If no scene name provided, calculate next available light number
    sceneName === undefined &&
      this.lightsInUI.forEach((name, ids) => {
        const nb = name.split('Light ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });

    // Use provided name or generate a default one
    const name = sceneName || `Light ${max + 1}`;

    // Add light to the UI mapping
    this.lightsInUI.set(id, name);

    // Trigger callback to inform UI about the new light
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.LIGHT,
          info: light,
        });
      }
    }
  }

  /**
   * Adds an asset to the UI with the specified name.
   *
   * @param id - Unique identifier for the asset
   * @param info - Asset model information
   * @param sceneName - Optional display name for the asset
   * @returns The assigned name of the asset
   */
  public addAssetToUI(id: string, info: AssetModelInfo, sceneName?: string): string {
    let max = 0;

    // If no scene name provided, calculate next available asset number
    sceneName === undefined &&
      this.asetsInUI.forEach((name, ids) => {
        const nb = name.split('Asset ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });

    // Use provided name or generate a default one
    const name = sceneName || `Asset ${max + 1}`;

    // Add asset to the UI mapping
    this.asetsInUI.set(id, name);

    // Trigger callback to inform UI about the new asset
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        const oa: SceneObject = {
          id: id,
          name: name,
          type: ObjectType.ASSET3D,
          info,
        };
        cb(oa);
      }
    }
    return name;
  }

  /**
   * Returns the container object that holds all user POV representations.
   *
   * @returns The user POV container object
   */
  public getUserPOVContainer(): Object3D {
    return this.userHolders;
  }

  /**
   * Adds a character to the UI with the specified name.
   *
   * @param id - Unique identifier for the character
   * @param char - Character model information
   * @param sceneName - Optional display name for the character
   * @param liveId - Optional identifier for live player characters
   * @returns The assigned name of the character
   */
  public addCharToUI(id: string, char: CharacterModelInfo, sceneName?: string, liveId?: string): string {
    let max = 0;

    // If no scene name provided, calculate next available character number
    sceneName === undefined &&
      this.charactersInUI.forEach((name, ids) => {
        const nb = name.split('Char ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });

    // Use provided name or generate a default one
    const name = sceneName || `Char ${max + 1}`;

    // Add character to the UI mapping
    this.charactersInUI.set(id, name);

    // Trigger callback to inform UI about the new character
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.CHARACTER,
          livePlayerId: liveId,
          info: char,
        });
      }
    }
    return name;
  }

  /**
   * Changes the name of a character in the UI and updates its 3D text display.
   *
   * @param id - Unique identifier of the character
   * @param newName - New name for the character
   */
  public changeCharName(id: string, newName: string) {
    // Find old name in the UI mapping
    let oldName = '';
    this.charactersInUI.forEach((name, ids) => {
      ids === id && (oldName = name);
    });

    // Update the character's name in the UI mapping
    this.charactersInUI.delete(id);
    this.charactersInUI.set(id, newName);

    // Update the character's 3D text display
    this.charactersInScene.get(id)?.createText3D(newName, this.sceneNamesVisible);
  }

  /**
   * Changes the animation duration for the scene.
   *
   * @param newDuration - New duration in seconds
   */
  public changeDuration(newDuration: number) {
    this.duration = newDuration;
  }

  /**
   * Gets the current animation duration for the scene.
   *
   * @returns Current duration in seconds
   */
  public getDuration(): number {
    return this.duration;
  }

  /**
   * Generates a unique asset name based on a keyword prefix.
   * Ensures all asset names are unique across characters, assets, cameras, and lights.
   *
   * @param find - Keyword prefix for the asset name
   * @returns Unique asset name
   */
  public getAssetName(find: string): string {
    let maxGlobal = 0;

    // Helper function to extract the maximum number from a name with the given prefix
    const getMax = (name: string): number => {
      let maxLocal = 0;
      let nb = name.split(`${find}`)[1];
      nb && (nb = nb.split(')')[0].split('(')[1]);
      name.indexOf(find) >= 0 && (maxLocal = 1);
      nb && parseInt(nb) && (maxLocal = Math.max(maxLocal, parseInt(nb) + 1));
      return maxLocal;
    };

    // Check all collections for existing names with the same prefix
    this.charactersInUI.forEach((name, ids) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });

    this.asetsInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });

    this.camerasInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });

    this.lightsInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });

    // Return name with number suffix if needed
    return `${find} ${maxGlobal > 0 ? `(${maxGlobal})` : ''}`;
  }

  /**
   * Generates the next available camera name based on existing cameras.
   *
   * @returns Next available camera name
   */
  public getNextCameraName(): string {
    let nmb = 1;

    // Find highest camera number and increment by 1
    this.camerasInScene.forEach((cam) => {
      const prevNmb = cam.name.split('Camera ')[1];
      const n = prevNmb ? parseInt(prevNmb) + 1 : 0;
      nmb = n > nmb ? n : nmb;
    });

    return `Camera ${nmb}`;
  }

  /**
   * Generates the next available light name based on existing lights.
   *
   * @returns Next available light name
   */
  public getNextLightName(): string {
    let nmb = 1;

    // Find highest light number and increment by 1
    this.lightsInScene.forEach((light) => {
      const prevNmb = light.name.split('Light ')[1];
      const n = prevNmb ? parseInt(prevNmb) + 1 : 0;
      nmb = n > nmb ? n : nmb;
    });

    return `Light ${nmb}`;
  }

  // -----------------------------------------------------------------------------
  // Scene Object Management Methods
  // -----------------------------------------------------------------------------

  /**
   * Adds a cinematic camera to the scene and registers it in the UI.
   *
   * @param cc - Cinematic camera to add
   */
  public addCinematicCamera(cc: CinematicCamera) {
    this.addCameraToUI(cc.uuid, cc.getAssetInfo(), cc.name);
    this.camerasInScene.set(cc.uuid, cc);
    this.add(cc);
  }

  /**
   * Removes a cinematic camera from the scene and UI.
   *
   * @param cc - Cinematic camera to remove
   */
  public removeCinematicCamera(cc: CinematicCamera) {
    this.camerasInScene.delete(cc.uuid);
    this.camerasInUI.delete(cc.uuid);
    this.remove(cc);
  }

  /**
   * Adds a light to the scene and registers it in the UI.
   *
   * @param cc - Light to add (point or spot)
   */
  public addLight(cc: RadicalPointLight | RadicalSpotLight) {
    this.addLightToUI(cc.uuid, cc.getAssetInfo(), cc.name);
    this.lightsInScene.set(cc.uuid, cc);
    this.add(cc.helper);
  }

  /**
   * Removes a light from the scene and UI.
   *
   * @param cc - Light to remove
   */
  public removeLight(cc: Light) {
    this.lightsInScene.delete(cc.uuid);
    this.lightsInUI.delete(cc.uuid);
  }

  /**
   * Adds a character to the scene and registers it in collections.
   * Sets up skeleton visibility and manages UI representation.
   *
   * @param obj - Character object to add
   */
  public addCharacterToScene(obj: RadicalCharacter) {
    // Set skeleton visibility based on current state
    obj.setSkeletonVisible(this.jointsVisible);

    // Get name from UI mapping if available
    const name = this.charactersInUI.get(obj.uuid);
    if (name) obj.name = name;

    // Add to character collection
    this.charactersInScene.set(obj.uuid, obj);

    // Add to scene
    this.add(obj);

    // Trigger callback to inform about character addition
    const cb = this.callbacks?.[CanvasCallbacksNames.OBJECT_ADDED];
    if (cb) {
      cb({
        id: obj.uuid,
        name: obj.name,
        type: ObjectType.CHARACTER,
        locked: obj.isLocked(),
        livePlayerId: obj.getLivePlayerId(),
        info: obj.getAssetInfo(),
      });
    }
  }

  /**
   * Gets all characters currently in the scene.
   *
   * @returns Array of all character objects
   */
  public getAllCharacters(): RadicalCharacter[] {
    return [...this.charactersInScene.values()];
  }

  /**
   * Gets all lights currently in the scene.
   *
   * @returns Array of all light objects
   */
  public getAllLights(): (RadicalPointLight | RadicalSpotLight)[] {
    return [...this.lightsInScene.values()];
  }

  /**
   * Gets all assets (non-character objects) currently in the scene.
   *
   * @returns Array of all asset objects
   */
  public getAllAssets(): AssetObject[] {
    const assetsOnly: AssetObject[] = [];
    this.assetsInScene.forEach((asset) => {
      if (asset instanceof AssetObject) assetsOnly.push(asset);
    });

    return assetsOnly;
  }

  /**
   * Retrieves a specific character from the scene by its identifier.
   *
   * @param id - Unique identifier of the character
   * @returns The character object if found, undefined otherwise
   */
  public getCharacterFromScene(id: string): RadicalCharacter | undefined {
    return this.charactersInScene.get(id) || undefined;
  }

  /**
   * Controls the visibility of character joints/skeletons across all characters.
   *
   * @param b - Boolean indicating whether joints should be visible
   */
  public toggleJointsVisibility(b: boolean) {
    this.jointsVisible = b;
    this.charactersInScene.forEach((char) => {
      char.setSkeletonVisible(b);
    });
  }

  /**
   * Gets the first character in the scene.
   *
   * @returns The first character if available, undefined otherwise
   */
  public getFirstCharacter(): RadicalCharacter | undefined {
    return this.charactersInScene.size > 0
      ? this.charactersInScene.get(this.charactersInScene.keys().next().value as string)
      : undefined;
  }

  /**
   * Retrieves a specific asset from the scene by its identifier.
   *
   * @param id - Unique identifier of the asset
   * @returns The asset object if found, undefined otherwise
   */
  public getAssetFromScene(id: string): AssetObject | Object3D | undefined {
    return this.assetsInScene.get(id) || undefined;
  }

  /**
   * Retrieves a specific camera from the scene by its identifier.
   *
   * @param id - Unique identifier of the camera
   * @returns The camera object if found, undefined otherwise
   */
  public getCameraFromScene(id: string) {
    return this.camerasInScene.get(id);
  }

  /**
   * Gets all cameras currently in the scene.
   *
   * @returns Map of camera identifiers to camera objects
   */
  public getAllCameras(): Map<string, CinematicCamera> {
    return this.camerasInScene;
  }

  /**
   * Retrieves a specific light from the scene by its identifier.
   *
   * @param id - Unique identifier of the light
   * @returns The light object if found, undefined otherwise
   */
  public getLightFromScene(id: string): RadicalSpotLight | RadicalPointLight | undefined {
    return this.lightsInScene.get(id);
  }

  /**
   * Gets all lights currently in the scene.
   *
   * @returns Map of light identifiers to light objects
   */
  public getAllLightsFromScene(): Map<string, RadicalPointLight | RadicalSpotLight> {
    return this.lightsInScene;
  }

  /**
   * Controls visibility of camera cones for all cameras in the scene.
   *
   * @param b - Boolean indicating whether camera cones should be visible
   */
  public camerasVisible(b: boolean) {
    this.camerasInScene.forEach((cam) => {
      cam.visible = b;
    });
  }

  /**
   * Gets the unique identifier of the current user.
   *
   * @returns User identifier if available
   */
  public getOwnId(): string | undefined {
    return this.ownId;
  }

  /**
   * Calculates the longest animation duration across all characters in the scene.
   *
   * @returns Duration in seconds of the longest animation
   */
  public calculateLongestDuration(): number {
    let longest = 0;
    this.charactersInScene.forEach((char) => {
      const total = char.getTotalDuration();
      longest = total > longest ? total : longest;
    });
    return longest;
  }

  /**
   * Removes an object from the scene and triggers necessary callbacks.
   *
   * @param obj - Object to remove
   * @returns Snapshot of the object's state before removal
   */
  public removeFromScene(obj: Object3D): ObjectSnapshot {
    // Take snapshot of object state before removal
    const snapshot = this.takeSnapshot(obj);

    // Remove from assets collection if present
    if (this.assetsInScene.delete(obj.uuid)) {
      this.asetsInUI.delete(obj.uuid);

      // Trigger callback to inform about object removal
      if (this.callbacks) {
        const cb = this.callbacks[CanvasCallbacksNames.OBJECT_REMOVED];
        if (cb) {
          cb(obj.uuid);
        }
      }
    }

    // Remove from scene
    this.remove(obj);
    return snapshot;
  }

  /**
   * Removes multiple objects from the scene.
   *
   * @param objs - Array of objects to remove
   */
  public removeMultipleFromScene(objs: Object3D[]) {
    objs.forEach((obj) => {
      this.remove(obj);
    });
  }

  /**
   * Removes a character from the scene and triggers necessary cleanup.
   *
   * @param uuid - Unique identifier of the character to remove
   * @returns Snapshot of the character's state before removal
   */
  public removeCharacterFromScene(uuid: string): ObjectSnapshot {
    const char = this.charactersInScene.get(uuid);
    let result = this.takeSnapshot();

    if (char) {
      // Remove from UI mapping
      this.charactersInUI.delete(uuid);

      // Trigger callback to inform about character removal
      if (this.callbacks) {
        const cb = this.callbacks[CanvasCallbacksNames.OBJECT_REMOVED];
        if (cb) {
          cb(uuid);
        }
      }

      // Take snapshot and clean up character
      result = this.takeSnapshot(char);
      this.charactersInScene.delete(uuid);
      this.remove(char);
      char.clear();
    } else {
      console.warn('Character: ', uuid, 'not found');
    }
    return result;
  }

  /**
   * Controls whether to show the environment or a blank background.
   *
   * @param b - Boolean indicating whether environment should be shown
   */
  public setIsEnvironment(b: boolean) {
    this.backgroundMesh.setIsEnvironment(b);
    if (b) {
      // Add environment components if floor projection is enabled
      this.envSettings.floorProjection && this.groundedSkybox && this.add(this.groundedSkybox);
      this.envSettings.floorProjection && this.shadowCatchingPlane && this.add(this.shadowCatchingPlane);
    } else {
      // Remove environment components
      this.groundedSkybox && this.remove(this.groundedSkybox);
      this.shadowCatchingPlane && this.remove(this.shadowCatchingPlane);
    }
  }

  /**
   * Controls visibility of the environment mesh.
   *
   * @param b - Boolean indicating whether environment mesh should be visible
   */
  public setEnvironmentMeshVisibility(b: boolean) {
    this.backgroundMesh && this.backgroundMesh.setVisibility(b);
  }

  /**
   * Sets the type of background to display.
   *
   * @param bg - Background type enum value
   */
  public setBackgroundType(bg: BackgroundType) {
    this.backgroundType = bg;
  }

  /**
   * Adds environment maps to the scene.
   * Configures the grounded skybox and background mesh with the provided textures.
   *
   * @param env - Cube texture for environment reflections
   * @param equirectangularEnv - Equirectangular texture for skybox
   */
  public addEnvironment(env: CubeTexture | Texture, equirectangularEnv: Texture) {
    // Remove existing grounded skybox if any
    if (this.groundedSkybox) {
      this.remove(this.groundedSkybox);
    }

    // Set environment map for reflections
    this.environment = env;

    // Set background texture
    this.backgroundMesh.setMap(equirectangularEnv);

    // -----------------------------------------------------------------------------
    // Create and configure grounded skybox
    // -----------------------------------------------------------------------------

    // Create new grounded skybox with the environment texture
    this.groundedSkybox = new GroundedSkybox(
      equirectangularEnv,
      paramsGroundedSkybox.height,
      paramsGroundedSkybox.radius
    );
    this.groundedSkybox.position.y = paramsGroundedSkybox.height - 0.01;
    this.groundedSkybox.material.onBeforeCompile = (shader: any) => updateGroundedSkyboxShader(shader);
    this.groundedSkybox.castShadow = true;
    this.groundedSkybox.receiveShadow = true;

    // Enable/disable grounded skybox based on current settings
    if (this.envSettings.floorProjection && this.backgroundType === BackgroundType.IMAGE) {
      this.add(this.groundedSkybox);
      this.removeFromScene(this.backgroundMesh);
    }

    // -----------------------------------------------------------------------------
    // Apply environment settings
    // -----------------------------------------------------------------------------

    // Set environment intensity
    this.environmentIntensity = this.envSettings.intensity;
    const color = new Color(generateGrayscaleColor(this.envSettings.intensity));
    this.backgroundMesh.setColor(color);
    this.groundedSkybox.material.color = color;

    // Set environment rotation
    const rad = MathUtils.degToRad(this.envSettings.rotation);
    this.environmentRotation.set(0, rad, 0);
    this.backgroundMesh.setRotation(0, Math.PI + rad, 0);
    this.groundedSkybox.rotation.set(0, rad, 0);

    // Configure material properties for proper rendering
    this.groundedSkybox.material.depthWrite = true;
    this.groundedSkybox.material.depthTest = true;
    this.groundedSkybox.material.needsUpdate = true;
    this.groundedSkybox.material.onBeforeCompile = (shader: any) => updateGroundedSkyboxShader(shader);
  }

  /**
   * Removes the current environment from the scene.
   */
  public removeEnvironment() {
    this.environment = null;
  }

  /**
   * Retrieves an object from the scene by its unique identifier.
   *
   * @param uuid - Unique identifier of the object
   * @returns The object if found, undefined otherwise
   */
  public getObjectByUuid(uuid: string): Object3D | undefined {
    return this.getObjectByProperty('uuid', uuid);
  }

  /**
   * Adds fog to the scene with the specified color.
   *
   * @param col - Color for the fog
   */
  public addFog(col: ColorRepresentation): void {
    const color = col;
    const near = 25;
    const far = 30;
    this.fog = new Fog(color, near, far);
  }

  /**
   * Controls visibility of the floor object.
   *
   * @param b - Boolean indicating whether floor should be visible
   */
  public showFloor(b: boolean): void {
    this.floor && (this.floor.visible = b);
  }

  /**
   * Updates a cinematic camera's parameters based on provided information.
   *
   * @param id - Unique identifier of the camera to change
   * @param camInfo - Camera parameters to update
   * @param aspect - Aspect ratio information
   */
  public changeCinematicCamera(id: string, camInfo: Partial<CameraInfo>, aspect: { w: number; h: number }) {
    const camera = this.getCameraFromScene(id);
    if (camera === undefined) return console.warn('Camera not found!');

    // Update camera FOV and lens if provided
    if (camInfo.fov && camInfo.lens) {
      camera.changeCameraFOV(camInfo.fov, camInfo.lens, aspect.w / aspect.h);
    }

    // Update depth of field settings if provided
    if (camInfo.depthOfField) {
      camera.getAssetInfo().depthOfField = camInfo.depthOfField;
    }
  }

  // -----------------------------------------------------------------------------
  // User and Visitor Management Methods
  // -----------------------------------------------------------------------------

  /**
   * Updates the list of users in the scene, adding or removing a user.
   *
   * @param params - Object containing user information and whether they are being added
   */
  public changeUserList({ user, added }: { user: VisitorInfo; added: boolean }): void {
    if (added) {
      // Extract user information
      const {
        location: { position, quaternion },
        thisClient,
        attendeeId,
        color,
      } = user;

      // Set own ID if this is the current user
      thisClient ? (this.ownId = attendeeId) : null;

      // Create position and quaternion vectors
      const p = new Vector3(position.x, position.y, position.z);
      const q = new Quaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

      // Create user camera representation
      const c = new UserCamera(attendeeId, 10, color);
      c.position.copy(p);
      c.quaternion.copy(q);
      c.visible = this.userPovVisible;

      // Add to scene if not the current user
      !thisClient ? this.userHolders.add(c) : null;

      // Store user information
      this.usersInScene.set(attendeeId, { cone: c, visitor: user });
    } else {
      // Remove user from scene
      const c = this.usersInScene.get(user.attendeeId)?.cone;
      if (c) {
        this.userHolders.remove(c);
        this.usersInScene.delete(user.attendeeId);
      }
    }
  }

  /**
   * Gets the color assigned to a specific user.
   *
   * @param id - User identifier
   * @returns Color string if user exists, undefined otherwise
   */
  public getUserColor(id: string): string | undefined {
    if (this.usersInScene.has(id)) {
      return this.usersInScene.get(id)?.visitor?.color;
    } else {
      console.warn('User color not found: ', id);
      return undefined;
    }
  }

  /**
   * Controls visibility of all user camera representations in the scene.
   *
   * @param b - Boolean indicating whether user cameras should be visible
   */
  public toggleUserCameras(b: boolean) {
    this.userPovVisible = b;
    this.usersInScene.forEach((user) => {
      user.cone.visible = b;
    });
  }

  /**
   * Updates a user's camera position, rotation, field of view, and target.
   *
   * @param id - User identifier
   * @param pos - New position vector
   * @param rot - New rotation quaternion
   * @param fov - New field of view
   * @param tgt - New target position
   */
  public updateUserCamera(id: string, pos: Vector3, rot: Quaternion, fov: number, tgt: Vector3) {
    const c = this.usersInScene.get(id);
    if (c) {
      // Update camera position and rotation
      c.cone.position.copy(pos);
      c.cone.quaternion.copy(rot);

      // Update visitor location information
      c.visitor.location.position = pos;
      c.visitor.location.quaternion = rot;
      c.visitor.location.target = tgt;
    } else {
      console.log('Audience cam not found: ', id);
    }
  }

  /**
   * Enables following a specific user's camera.
   *
   * @param id - User identifier to follow, or undefined to stop following
   * @returns Boolean indicating whether following was successfully enabled
   */
  public followUser(id: string | undefined): boolean {
    // Stop any current following
    this.stopFollowing();

    // Set up new following if ID provided
    this.followingUser = id ? this.usersInScene.get(id) : undefined;
    if (this.followingUser) {
      // Hide the followed user's camera
      this.followingUser.cone && (this.followingUser.cone.visible = false);
      return true;
    }
    return false;
  }

  /**
   * Stops following the currently followed user.
   */
  public stopFollowing() {
    // Restore visibility of the followed user's camera
    this.followingUser ? this.followingUser.cone && (this.followingUser.cone.visible = true) : null;
    this.followingUser = undefined;
  }

  /**
   * Updates camera visibility based on user following relationships.
   * Controls which user cameras are visible based on follow status.
   *
   * @param user - ID of the user performing the follow action
   * @param follow - ID of the user being followed, or undefined if stopping following
   */
  public userFollowing(user: string, follow: string | undefined) {
    // Get the user's 3D representation
    const u = this.usersInScene.get(user);
    if (u === undefined) return;

    // Handle current user's camera visibility
    if (user === this.ownId) {
      u.cone.visible = false;
      return;
    }

    // Handle following state changes
    if (follow) {
      // User is following someone, hide their camera
      u.cone.visible = false;
    } else {
      // User stopped following, determine visibility
      if (this.followingUser) {
        // Show camera unless this user is being followed
        u.cone.visible = this.followingUser.visitor.attendeeId === user ? false : true;
      } else {
        // No one is being followed, show camera
        u.cone.visible = true;
      }
    }

    // Special case: user is being followed by current user
    if (follow === this.ownId) {
      u.cone.visible = false;
    } else if (follow === undefined) {
      // User stopped following, determine visibility
      if (this.followingUser) {
        if (this.followingUser.visitor.attendeeId !== user) u.cone.visible = true;
        else u.cone.visible = false;
      } else u.cone.visible = true;
    } else {
      // User started following someone
      console.log(`User ${user} starts following ${follow}`);

      // If user is following the same person we're following, hide their camera
      if (this.followingUser && follow === this.followingUser.visitor.attendeeId) {
        u.cone.visible = false;
      }
    }

    // Update the user's following status
    u.visitor.following = follow;
  }

  /**
   * Gets the user that is currently being followed.
   *
   * @returns The followed user object if any, undefined otherwise
   */
  public getFollowed(): Visitor3D | undefined {
    return this.followingUser;
  }

  /**
   * Takes a snapshot of an object's current transform state.
   * Used for undoing operations or tracking state changes.
   *
   * @param o - Optional object to snapshot, or undefined for empty snapshot
   * @returns Object containing position, rotation, and scale state
   * @private
   */
  private takeSnapshot(o?: Object3D): ObjectSnapshot {
    return {
      position: o ? o.position.clone() : undefined,
      quaternion: o ? o.quaternion.clone() : undefined,
      scale: o?.scale.clone(),
    };
  }

  /**
   * Creates a loader mesh with wireframe visualization and a fading shader.
   * Used for loading animations when initializing characters.
   *
   * @returns The created loader mesh object
   * @private
   */
  private createLoaderMesh(): Object3D {
    // -----------------------------------------------------------------------------
    // Define custom shader for wireframe visualization
    // -----------------------------------------------------------------------------

    const timeLineShader = {
      uniforms: {
        color: { value: new Color(0x0081d1) }, // Blue color for wireframe
        time: { value: 0 }, // Time parameter for fading effect
      },
      vertexShader: `
        attribute float count;
        varying float vCount;
        void main() {
          vCount = count;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
        }
      `,
      fragmentShader: `
        #include <common>

        varying float vCount;
        uniform vec3 color;
        uniform float time;

        // layout(location = 0) out vec4 base_FragColor;
        // layout(location = 1) out vec4 depth_FragColor;

        void main() {
          if (vCount > time) {
            discard;
          }
          base_FragColor = vec4(color, 0.5);
          //bloom_FragColor = vec4(0.0);
          depth_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
        }
      `,
    };

    // Create shader material for the wireframe
    const material = new ShaderMaterial(timeLineShader);
    material.transparent = true;
    material.opacity = 0.5;

    // Set up model loaders
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    loader.setDRACOLoader(dracoLoader);

    // Create container object
    const obj = new Object3D();
    let wireframeGeometry: WireframeGeometry;

    // Load placeholder character model
    loader.load(filePathCharPlaceholder, (gltf) => {
      // Scale and position the model appropriately
      gltf.scene.scale.set(0.004, 0.004, 0.004);
      gltf.scene.scale.set(0.01, 0.01, 0.01);
      gltf.scene.position.y = 1.05;

      // Process each mesh in the loaded model
      gltf.scene.traverse((child: any) => {
        if (child instanceof Mesh) {
          // Set wireframe mode on original material
          child.material.wireframe = true;

          // Create wireframe geometry from the mesh
          wireframeGeometry = new WireframeGeometry(child.geometry);

          const numVertices = wireframeGeometry.getAttribute('position').count;
          const counts = new Float32Array(numVertices);

          // Define line segments by connecting points sequentially
          // Every 2 points forms one line segment
          const numSegments = numVertices / 2;
          for (let seg = 0; seg < numSegments; ++seg) {
            const off = seg * 2;
            counts[off + 0] = seg;
            counts[off + 1] = seg + 1;
          }

          // Create buffer attribute for the counts
          const itemSize = 1;
          const normalized = false;
          const colorAttrib = new BufferAttribute(counts, itemSize, normalized);
          wireframeGeometry.setAttribute('count', colorAttrib);

          // Create line segments with the custom shader
          const line = new LineSegments(wireframeGeometry);
          line.rotation.x = -Math.PI / 2;
          line.rotation.z = -Math.PI / 2;
          line.material = material;
          line.position.y = 1.05;

          // Create a second line with basic material for additional visual effect
          const line2 = line.clone();
          line.name = 'main';
          line2.material = new LineBasicMaterial({ color: 0x0081d1, transparent: true, opacity: 0.1 });

          // Add both lines to the container object
          obj.add(line);
          obj.add(line2);
        }
      });
    });

    return obj;
  }

  /**
   * Creates a visualizer floor by loading a 3D model.
   * The floor serves as a reference plane for objects in the scene.
   *
   * @param isLive - Whether this is for a live session (affects scale)
   * @returns The created floor object
   * @private
   */
  private createVisualizerFloor(isLive?: boolean): Object3D {
    // Set up model loaders
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    loader.setDRACOLoader(dracoLoader);

    // Create container object
    const obj = new Object3D();

    // Load floor model
    loader.load(filePathVisualizerPlane, (gltf) => {
      // Process each mesh in the loaded model
      gltf.scene.traverse((child: any) => {
        if (child.material) {
          // Apply custom shader to handle selective bloom
          child.material.onBeforeCompile = (shader: any) => updateGroundedSkyboxShader(shader);

          // Configure shadow properties
          child.castShadow = false;
          child.receiveShadow = true;
          child.material.envMapIntensity = 1;
        }
      });

      // Scale up for live mode
      isLive && gltf.scene.scale.set(2, 2, 2);

      // Add to container
      obj.add(gltf.scene);
    });

    // Position slightly below ground level
    obj.position.y = -0.02;
    return obj;
  }

  /**
   * Gets the background mesh used in the scene.
   *
   * @returns The background mesh object
   */
  public getBackgroundMesh(): BackgroundMesh {
    return this.backgroundMesh;
  }

  /**
   * Cleans up resources and releases references.
   * Call this method when the stage is no longer needed.
   */
  public destroy() {
    delete this.callbacks;
  }
}
